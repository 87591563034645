.rule-pages {
    width: 90% !important;
    max-width: 500px !important;
    height: 90vh !important;
    background-color: #5328bb;
    border-radius: 8px;
    position: relative;
}

.header_top {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.main_img{
    width: 100% !important;
   
}
.main_center{
        height: 500px;
            flex-direction: column;
            overflow: auto;
}
@primary-color: #1DA57A;