.goods-modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 20px 0px 24px 0px;
  // width: 340px;
      width: 360px;
  height: 50vh;
  // background-color: #ffffff;
background-image: url('../../../../assets/gaobai/windwos.png');
background-repeat: no-repeat;
background-size: 100% 100%;
}

.goods-modal-title {
  align-self: center;
  text-align: center;
  font-size: 18px;
  color: #050303;
  position: absolute;
  top: -55px;
  width: 100vw;
  font-weight: medium;
}

.goods-modal-title-show {
  align-self: center;
  font-size: 13px;
  color: #F03550;
  margin-top: 30px;
}

.goods-model-one {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
 margin-left: 15px;
}

.goods-model-one-img {
  align-self: center;
}

.goods-model-one-text {
  align-self: center;
  padding-top: 8px;
}

.goods-model-one-description {
  align-self: center;
  color: #979797;
  font-size: 12px;
}

.goods-model-one-button {
  background: linear-gradient(to right, #cc64e1, #cc64e1);

  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}

.ant-modal-content {
  background: transparent;
}

.goods-modal {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.goods-model-close {
  position: absolute;
  align-self: center;
  // right: 40px;
  // top: -50px;
    right: 20px;
      top: 2px;
}

.goods-model-list {
  margin-top: 36px !important;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 37px;
      width: 74%;
      height: 65%;
      overflow-y: auto;
}

.goods-model-list-button {
  background: #7435fd;
  align-self: center;
  width: 180px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;

  font-size: 14px;
  color: #ffffff;
  font-weight: medium;
  margin-top: 32px;
}
.bottom_button{
      position: absolute;
        bottom: -2px;
        width: 100%;
        left: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        color: #ffffff;
  img{
        width: 83px;
          // /* margin-top: 4px;
  }
}
.goods-modal-item {
  // padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.goods-model-item-text {
  padding-left: 8px;
}

.goods-modal-item-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  margin-right: 8px;

}

.goods-modal-item-show {
  font-size: 13px;
  color: #F03550;
  margin-right: 8px;

}

.goods-modal-item-left {
  font-size: 15px;
  color: #343537;
}

.ant-modal-body {
  padding: 0 !important;
}
@primary-color: #1DA57A;