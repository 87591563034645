.wheel-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.wheel-container-base {

  // background-image: url("../../assets/shengdeng/backgrounds.png");
  background-image: url("../../assets/gaobai/backBG.png");
  // background-image: url("../../assets/motianlun/lowBack.png");
  // background-image: url("../../assets/bg_zp_chuji@3x.png");
  background-size: cover;
  background-position: center;
}

.wheel-container-middle {
  background-image: url('../../assets/motianlun/centerBack.png');
  // background-image: url("../Wheel//components/MiddleWheel/assets/alterWheel/bg_1@3x.png");
  background-size: cover;
  background-position: center;

}

.wheel-container-advanced {
  // background-image: url("../../assets/bg_zp_gaoji@3x.png");
  background-image: url('../../assets/motianlun/highBack.png');
  background-size: cover;
  background-position: center;
}

.more-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  position: "relative";
}

.more-welcome {
  width: 260px;
  height: 100px;
}

.wheel-more {
  color: white;
  position: absolute;
  right: 16px;
  top: 33px;
  position: absolute;
  z-index: 10;
}

.wheel-more img {
  width: 24px;
  height: 24px;
}

.tab-container {
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: -8px;
}

.tab-item-left-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_chuji_left_s@3x.png");
  background-image: url("../../assets/motianlun/lowbuttonActive.png");
  background-size: cover;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.tab-item-right-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/advanced@3x.png");
  background-image: url("../../assets/motianlun/highbottonAction.png");
  background-size: cover;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.tab-item-middle-active {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_middle_s@3x.png");
  background-image: url("../../assets/motianlun/centerAction.png");
  background-size: cover;
  // width: 95px;
  // height: 36px;
  // padding-top: 7px;
  // padding-left: 32px;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 14px;
}



.tab-item-middle {
  color: white;
  font-size: 16px;
  background-image: url("../../assets/motianlun/centerButton.png");
  // background-image: url("../../assets/btnbg_zp_middle_n@3x.png");
  background-size: cover;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 0 14px;
}

.tab-item-right {
  color: white;
  font-size: 16px;
  background-image: url("../../assets/motianlun/highButton.png");

  background-size: cover;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.tab-item-left {
  color: white;
  font-size: 16px;
  // background-image: url("../../assets/btnbg_zp_chuji_left_n@3x.png");
  background-image: url("../../assets/motianlun/lowButton.png");
  background-size: cover;
  width: 100px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.star-add-container {
  position: absolute;
  top: 164px;
  left: 0px;
  z-index: 100;
}

.text-container {
  position: relative;
  // margin-top: 16px;
  // margin-bottom: 20px;
  margin-top: -35px;
  margin-bottom: 57px;
}

.lucky-wheel-container {
  align-self: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 0 12px;
  margin-top: 15px;
  position: relative;
  height: 54px;
  justify-content: center;
  gap: 10px;
}

.buttons1 {
  display: flex;
  flex-direction: row;
  margin: 0 12px;
  // height: 54px;
  margin-top: 15px;
  position: relative;
}

.buttons:last-child {
  margin-right: 0px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom {
  align-self: center;
  margin-top: 10px;
  color: #ffc938;
  font-size: 13;
  position: relative;
}

.button {
  // background-image: url("../../assets/btnbg_zp_chuji_chouj_s@3x.png");
  // background-image: url("../../assets/motianlun/bottomActive.png");
  // background-image: url("../../assets/shengdeng/botton.png");
  background-image: url("../../assets/gaobai/botton.png");
  background-repeat: no-repeat;
  background-size: 100% 144%;
  background-position-y: -10px;
  flex: 1;

  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  max-width: 130px;
}

.disable-button {
  // background-image: url("../../assets/shengdeng/botton.png");
  background-image: url("../../assets/gaobai/botton.png");
  background-repeat: no-repeat;
  // background-image: url("../../assets/btnbg_zp_chuji_chouj_n@3x.png");
  background-size: 100% 144%;
  background-position-y: -10px;
  flex: 1;
  // margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  max-width: 130px;
}

.button-advanced {
  background-image: url("../../assets/shengdeng/botton.png");
  background-repeat: no-repeat;
  // background-image: url("../../assets/btnbg_zp_gaoji_chouj_s@3x.png");
  background-size: contain;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}


.disable-button-advanced {
  background-image: url("../../assets/motianlun/bottomButton.png");
  // background-image: url("../../assets/btnbg_zp_gaoji_chouj_n@3x.png");
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}

.button-middle {
  background-image: url("../../assets/btnbg_zp_chuji_chouj_s_m@3x.png");
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}

.disable-button-middle {
  background-image: url("../../assets/btnbg_zp_chuji_chouj_n_m@3x.png");
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  justify-content: center;
}

.button-middle_noLeft {
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/noLeft@3x.png");
  background-image: url("../../assets/motianlun/bottomButton.png");
  color: #5131cd;
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  // justify-content: center;
  padding: 12px 0;
}

.button-middle_yesLeft {
  color: #5131cd;
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/yesLeft@3x.png");
  background-image: url("../../assets/motianlun/bottomActive.png");
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  padding: 12px 0;
  // justify-content: center;
}

.button-middle_nocenter {
  background-image: url("../../assets/motianlun/bottomButton.png");
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/noCente@3x.png");
  color: #5131cd;
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  // justify-content: center;
  padding: 12px 0;
}

.button-middle_yescenter {
  background-image: url("../../assets/motianlun/bottomActive.png");
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/yesCent@3x.png");
  background-size: cover;
  color: #5131cd;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  padding: 12px 0;
  // justify-content: center;
}

.button-middle_noright {
  background-image: url("../../assets/motianlun/bottomButton.png");
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/noRight@3x.png");
  color: #5131cd;
  background-size: cover;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  // justify-content: center;
  padding: 12px 0;
}

.button-middle_yesright {
  background-image: url("../../assets/motianlun/bottomActive.png");
  // background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/yesRight@3x.png");
  background-size: cover;
  color: #5131cd;
  flex: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  aspect-ratio: 110 / 44;
  align-items: center;
  padding: 12px 0;
  // justify-content: center;
}

.disable-button .count {
  // color: white;
  color: #f6c8cb;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
}

.disable-button .label {
  // background: linear-gradient(to right, #f6f7f9, #ffe1e1);

  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
  color: #f6c8cb;
}

.disable-button-advanced .count {
  color: #faf3c3;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
}

.disable-button-advanced .label {
  // background: linear-gradient(to right, #f6f7f9, #ffe1e1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
  color: rgb(81, 49, 205);
}

.button-advanced .count {
  color: rgb(81, 49, 205);
  font-size: 17px;
  align-self: center;
  line-height: 24px;
}

.button-advanced .label {
  // background: linear-gradient(to right, #f6f7f9, #ffe1e1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  color: rgb(81, 49, 205);
  ;
}

.button .count {
  // color: white;
  color: #f6c8cb;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
}

.button .label {
  // background: linear-gradient(to right, #f6f7f9, #ffe1e1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  color: #f6c8cb;
}

.button-middle .count {
  color: white;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
}

.float-text {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 90%;
  height: 27px;
  background-image: url("../Wheel/components/MiddleWheel/assets/alterWheel/float@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.button-middle .label {
  background: linear-gradient(to right, #f6f7f9, #ffe1e1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
}

.disable-button-middle .count {
  color: white;
  font-size: 17px;
  align-self: center;
  line-height: 24px;
  opacity: 0.7;
}

.disable-button-middle .label {
  background: linear-gradient(to right, #f6f7f9, #ffe1e1);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 12px;
  align-self: center;
  line-height: 14px;
  opacity: 0.7;
}

.load-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  border-radius: 0px 23px 23px 0px;
  z-index: 999px;
}

.star-count {
  font-size: 15px;
  color: #ffffff;
  padding-left: 2px;
  padding-right: 10px;
}

.ant-drawer-content {
  background-color: transparent;
}

.jinfen-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.jinfen-title {
  font-size: 17;
  color: #ffffff;
  align-self: center;
  margin-top: 20px;
}

.jinfen-close {
  position: absolute;
  top: 20px;
  right: 16px;
}

.jinfen-show-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 18px 16px 0px 16px;
}

.jinfen-show-container-count {
  color: white;
  margin-left: 4px;
}

.jinfen-show-container-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shengdou-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: hsla(0, 0%, 100%, 0.05);
  padding: 2px 6px 2px 5px;
  border-radius: 23px;
}

.shengdou-container-count {
  color: white;
  margin-left: 4px;
  margin-right: 8px;
}

.jinfen-get-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 16px 16px 16px;
}

.jinfen-item-container:last-child {
  margin-right: 0px;
}

.jinfen-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #8484841A;
  padding-top: 8px;
  padding-bottom: 8px;
  flex: 1;
  margin-right: 9px;
  border-radius: 8px;
}

.jinfen-item-active {
  outline: 1px solid #ff3f9c;
}

.jinfen-item-price-text {
  font-size: 14px;
  color: #ffffff;
  padding-top: 8px;
}

.jinfen-item-description-text {
  font-size: 12px;
  color: #979797;
}

.jinfen-button {
  height: 44px;
  margin: 0px 16px;
  border-radius: 8px;
  background: linear-gradient(to right, #cc64e1, #825ced);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
}

.jinfen-button-container {
  margin-top: 10px;
  margin-bottom: 30px;
}

.ant-popover-inner-content {
  padding: 12px;
  border-radius: 8px;
}

.more-item {
  font-size: 14;
  color: #979797;
  padding-bottom: 16px;
}

.more-item:last-child {
  padding-bottom: 0px;
}

.mySwiper {
  // width: 100%;
  height: 22px;
}

//
.house_box img {
  // background-image: url('../../assets/motianlun/lowHome.png');
  // background-size: cover;
  position: absolute;
  width: 257px;
  height: 200px;
  top: 60%;
  left: 17%;

}

//摩天轮的旋转
.house_center img {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 51%;
  left: 41%;
  z-index: 9999999;
}

// .rotate {
//   animation: spin 10s linear infinite;
// }

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.progress_box {
  position: absolute;
  // background-color: red;
  top: -130px;
  background-image: url('../../assets/gaobai/outBox.png');
  background-size: 100% 100%;
  width: 220px;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);

  .mix_out {
    position: relative;
    width: 58%;
    left: 30%;
    top: 31%;
    // transform: translateY(-50%);
  }
  .xin_box{
    position: absolute;
    width: calc(100% - 34px);
    height: 10px;
    left: 10px;
    // background-color: red;
    z-index: 100;
    
  }
  .xin_out{
      position: relative;
      width: 100%;
      height: 10px;
    }
  .mix_box {
    position: absolute;
    width: 100%;
    // width: 128px;
    height: 40px;
    left: 0px;
    top: -10px;
    z-index: 4;
  }

  .icon_box {
    // position: absolute;
    width: 40px;
    height: 40px;
    margin-top: -10px;
    // left: -4px;
    z-index: 10;
  }

  .icon_box2 {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -10px;
    // left: -5px;
    margin-left: -4px;
    z-index: 10;
  }

  .max_box {
    position: absolute;
    left: 6px;
    width: 0%;
    // max-width: 128px;
    z-index: 5;
    height: 40px;
    // left: 0;
    top: -10px;
  }

  .max_box2 {
    position: absolute;
    left: 0px;
    width: 25%;
    // max-width: 128px;
    z-index: 5;
    height: 40px;
    // left: 0;
    top: -10px;
  }
}

.center_img1 {
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 312px;
  left: 0;
  display: flex;
  justify-content: center;

  img {
    width: 50%;
    max-width: 140px;
    height: 100%;
  }
}
@primary-color: #1DA57A;