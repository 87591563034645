.center_img {
    // width: 360px;
    height: 360px;

    // background-image: url('../../../../assets/shengdeng/deng.png');
    // background-repeat: no-repeat;
    // background-size: 100% 100%
    // background-color: red;
}

.center_img_bg {
    position: absolute;
    width: 100%;
    // height: calc(100% - 25px); 
    height: 650px;
    top: 0px;
    left: 0;
    display: flex;
    justify-content: center;

    // height: 100%;
    img {
        width: 100%;
        max-width: 407px;
        height: 100%;
        margin-top: -40px;
    }
}

.svga {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 650px;
    top: -40px;
    left: 0;
    // background-color: red;
}

.svgaB {
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 650px;
    top: -40px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    // background-color: red;
}

.bottons {
    position: absolute;
    top: 0px;
    left: 0px;
}

.aas {
    position: absolute;
    width: 100%;
    // max-height: 500px;
    height: 100%;
    background-color: pink;
}
@primary-color: #1DA57A;